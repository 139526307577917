<template>
  <div class="header__personal">
    <div class="personal" :class="{'personal-auth': isAuth}">
      <span class="header__personal-drop-wrapper">
        <nuxt-link :to="USERS" class="personal__info" :class="{personal__info_bordered: !isAuth}">
          <profile-svg id="profile_main"></profile-svg>
          {{ isAuth ? 'Кабинет' : 'Войти' }}
        </nuxt-link>
        <layout-hovered-block-auth v-if="isAuth" :drop-list="asideMenu"></layout-hovered-block-auth>
      </span>

      <layout-hovered-block
        v-if="!isAuth"
        class="personal__auth_main"
        :basket-count="smallInfo.count"
        :compare-count="compareCount"
        :is-auth="isAuth"
      ></layout-hovered-block>

      <div v-if="isAuth" class="personal__bonuses">
        ТМ-бонусы:
        <div class="personal__count">{{ bonuses }}</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {type PropType, computed} from '#imports';
import {USERS} from '@/composables/useURL';
import {asideMenu} from '@/services/cabinet';
import {useAuthStore} from '@/stores/auth';
import {usePersonalStore} from '@/stores/personal';
import type {IGetBasketZeroResponse} from '@/types/store/basket';
import ProfileSvg from 'svg/header/profile.vue';
import LayoutHoveredBlockAuth from '../../hovered/block/auth/index.vue';
import LayoutHoveredBlock from '../../hovered/block/index.vue';

defineProps({
  smallInfo: {type: Object as PropType<IGetBasketZeroResponse>, required: true},
  compareCount: {type: Number, required: false},
});

const authStore = useAuthStore();
const personalStore = usePersonalStore();

const isAuth = computed(() => authStore.isAuth);
const bonuses = computed(() => personalStore.bonuses);
</script>
