<template>
  <div class="header__top">
    <div class="container">
      <div class="header__wrapper">
        <layout-header-top-location
          :info="[
            {name: 'Адрес и контакты', url: '/contacts/'},
            {name: 'Оплата и доставка', url: '/oplata-i-dostavka/'},
          ]"
        ></layout-header-top-location>
        <layout-header-top-numbers></layout-header-top-numbers>
        <layout-header-top-about :top-links="menuData?.top || []"></layout-header-top-about>
        <layout-header-top-languages></layout-header-top-languages>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed} from '#imports';
import {useRootStore} from '@/stores/root';
import LayoutHeaderTopAbout from './about.vue';
import LayoutHeaderTopLanguages from './languages.vue';
import LayoutHeaderTopLocation from './location.vue';
import LayoutHeaderTopNumbers from './numbers.vue';

const rootStore = useRootStore();

const menuData = computed(() => rootStore.menuData);
</script>
