<template>
  <div class="modal-menu" :class="{'modal-menu-auth': isAuth}">
    <div class="modal-menu_header">
      <div class="container">
        <div class="modal-menu_header_wrapper">
          <div class="modal-menu__back" @click="emit('close-menu')">
            <small-arrow-svg left color="#FFFFFF"></small-arrow-svg>
            Назад
          </div>
          <label
            style="width: 100%; max-width: 513px"
            class="header__search header__search_mobile"
            :class="{header__search_focused: isSubSearchFocused}"
            for="search"
          >
            <input
              id="search"
              type="search"
              placeholder="Поиск по названию, конфигурации или parts numbers"
              @focus="isSubSearchFocused = true"
              @blur="isSubSearchFocused = false"
            />
            <mobile-search-svg id="mobile_search_sub"></mobile-search-svg>
          </label>
        </div>
      </div>
    </div>
    <div class="modal-menu_wrapper" @mouseleave="firstHoveredItem = null">
      <ul class="modal-menu__main scrollbar-y">
        <li>
          <nuxt-link
            v-for="item in childs"
            :key="item.name"
            class="modal-menu__elem"
            :class="{
              'modal-menu__elem-selected': item === firstHoveredItem,
              disabled: !item.url,
            }"
            :to="removeLastSlashFromLink(item.url)"
            @mouseover="selectFirstHover(item)"
            @mouseleave="clearFirstTimeout()"
            @click="emit('close-menu')"
          >
            <img :src="item.svg" :alt="item.name" class="modal-menu__svg" />
            <div class="modal-menu__text" @mouseover="secondHoveredItem = item">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <span v-html="item.name"></span>
              <small-arrow-svg class="is-phone"></small-arrow-svg>
            </div>
          </nuxt-link>
        </li>
      </ul>
      <template v-if="firstHoveredItem">
        <div class="modal-menu__wrapper-new scrollbar-y">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <h2 v-html="firstHoveredItem.name"></h2>
          <div class="modal-menu__sub-wrapper-new scrollbar-y">
            <div
              v-for="subItem in firstHoveredItem.childs"
              :key="subItem.id"
              class="modal-menu__sub-elem-new"
            >
              <div class="modal-menu__sub-elem-new">
                <!-- eslint-disable vue/no-v-html, vue/no-v-text-v-html-on-component -->
                <nuxt-link
                  class="modal-menu__elem-link-new"
                  :class="{
                    disabled: !subItem.url,
                  }"
                  :to="removeLastSlashFromLink(subItem.url)"
                  @click="emit('close-menu')"
                  v-html="subItem.name"
                ></nuxt-link>
                <!-- eslint-enable vue/no-v-html, vue/no-v-text-v-html-on-component -->
              </div>
              <div v-if="subItem.childs?.length" class="modal-menu__second-wrap-new">
                <div
                  v-for="secondItem in subItem.childs"
                  :key="secondItem.id"
                  class="modal-menu__second-elem-new"
                >
                  <!-- eslint-disable vue/no-v-html, vue/no-v-text-v-html-on-component -->
                  <nuxt-link
                    class="modal-menu__elem-link-new"
                    :class="{
                      disabled: !secondItem.url,
                    }"
                    :to="removeLastSlashFromLink(secondItem.url)"
                    @click="emit('close-menu')"
                    v-html="secondItem.name"
                  ></nuxt-link>
                  <!-- eslint-enable vue/no-v-html, vue/no-v-text-v-html-on-component -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="parterns">
        <div class="partners_wrapper">
          <ul class="partners__logos">
            <li v-for="partner in partnersList" :key="partner.id" class="partners_logo hovered">
              <nuxt-link :to="removeLastSlashFromLink(partner.url)" itemprop="url">
                <img class="partners__img" :src="partner.img" alt="partner" />
              </nuxt-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import 'sass/utilites.scss';

import {type PropType, computed, onBeforeUnmount, onMounted, ref} from '#imports';
import {useAuthStore} from '@/stores/auth';
import type {IChild2Response} from '@/types/store/store';
import {removeLastSlashFromLink} from '@/utils';
import {partnersList} from 'services/header';
import MobileSearchSvg from 'svg/header/mobileSearch.vue';
import SmallArrowSvg from 'svg/header/smallArrow.vue';

const props = defineProps({
  childs: {type: Array as PropType<IChild2Response[]>, required: true},
});

const emit = defineEmits<{
  (e: 'close-menu'): void;
}>();

const authStore = useAuthStore();

const firstTimeout = ref<ReturnType<(typeof window)['setTimeout']>>();

const isAuth = computed(() => authStore.isAuth);

const isSubSearchFocused = ref(false);

const firstHoveredItem = ref(props.childs.at(0));
const secondHoveredItem = ref<IChild2Response>();

const setBodyColor = (color: string) => {
  document.querySelector<HTMLElement>('.main_container')!.style.background = color;
  document.querySelector<HTMLElement>('.header')!.style.background = color;
};

onMounted(() => {
  setBodyColor('#e9e9e9');
});
onBeforeUnmount(() => {
  setBodyColor('#fff');
});

const clearFirstTimeout = () => {
  clearTimeout(firstTimeout.value);
};

const selectFirstHoverClear = (item: IChild2Response) => {
  if (firstHoveredItem.value) {
    firstHoveredItem.value = undefined;
  }
  firstHoveredItem.value = item;
  secondHoveredItem.value = undefined;
};

const selectFirstHover = (item: IChild2Response) => {
  clearFirstTimeout();
  firstTimeout.value = setTimeout(() => {
    selectFirstHoverClear(item);
  }, 200);
};
</script>
