<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" fill="none" />
    <path
      d="M19.9702 16.3388H5.65783C5.41354 16.3388 5.17926 16.2418 5.00652 16.069C4.83378 15.8963 4.73674 15.662 4.73674 15.4177V5.07315L2.39858 3.65609C2.21022 3.52634 2.07687 3.33115 2.02449 3.10851C1.9721 2.88587 2.00442 2.6517 2.11516 2.45158C2.23391 2.25115 2.4242 2.10316 2.6477 2.03743C2.87121 1.97169 3.11131 1.99309 3.31967 2.09731L6.08295 3.79779C6.22471 3.869 6.34136 3.98177 6.41733 4.12104C6.49329 4.26031 6.52495 4.41944 6.50807 4.57718V14.5675H19.9702C20.2027 14.5668 20.4269 14.6541 20.5977 14.8118C20.7685 14.9695 20.8734 15.1859 20.8913 15.4177C20.8913 15.662 20.7943 15.8963 20.6215 16.069C20.4488 16.2418 20.2145 16.3388 19.9702 16.3388Z"
      fill="#323232"
    />
    <path
      d="M7.78345 3.01834H13.31C13.8174 3.01834 14.304 3.21989 14.6627 3.57866C15.0215 3.93742 15.223 4.42401 15.223 4.93138V13.4338H7.78345V3.01834Z"
      fill="#323232"
    />
    <path d="M19.9702 8.75754H16.3567V13.3772H19.9702V8.75754Z" fill="#323232" />
    <path d="M19.9702 5.9234H16.3567V7.8577H19.9702V5.9234Z" fill="#323232" />
    <path
      d="M7.28736 22.9991C6.77712 23.0131 6.27441 22.8736 5.84425 22.5988C5.4141 22.324 5.07629 21.9265 4.87451 21.4576C4.67273 20.9888 4.61626 20.4701 4.71241 19.9688C4.80856 19.4675 5.0529 19.0066 5.41384 18.6457C5.77477 18.2847 6.2357 18.0404 6.737 17.9443C7.2383 17.8481 7.75692 17.9046 8.22579 18.1063C8.69465 18.3081 9.09219 18.6459 9.36699 19.0761C9.6418 19.5063 9.78122 20.009 9.76723 20.5192C9.74936 21.1713 9.48235 21.7917 9.0211 22.2529C8.55985 22.7142 7.93942 22.9812 7.28736 22.9991ZM7.28736 19.7398C7.08066 19.7398 6.88242 19.8219 6.73626 19.9681C6.59009 20.1143 6.50798 20.3125 6.50798 20.5192C6.50748 20.6185 6.52787 20.7168 6.56782 20.8078C6.60777 20.8987 6.6664 20.9802 6.73989 21.047C6.81338 21.1138 6.9001 21.1645 6.99442 21.1956C7.08873 21.2267 7.18854 21.2377 7.28736 21.2277C7.47528 21.2277 7.6555 21.1531 7.78837 21.0202C7.92125 20.8873 7.9959 20.7071 7.9959 20.5192C7.99671 20.3242 7.92439 20.136 7.79322 19.9917C7.66205 19.8474 7.48156 19.7575 7.28736 19.7398Z"
      fill="#323232"
    />
    <path
      d="M16.8526 22.9991C16.3423 23.0131 15.8396 22.8736 15.4094 22.5988C14.9793 22.324 14.6415 21.9265 14.4397 21.4576C14.2379 20.9888 14.1814 20.4701 14.2776 19.9688C14.3737 19.4675 14.6181 19.0066 14.979 18.6457C15.34 18.2847 15.8009 18.0404 16.3022 17.9443C16.8035 17.8481 17.3221 17.9046 17.791 18.1063C18.2598 18.3081 18.6574 18.6459 18.9322 19.0761C19.207 19.5063 19.3464 20.009 19.3324 20.5192C19.3145 21.1713 19.0475 21.7917 18.5863 22.2529C18.125 22.7142 17.5046 22.9812 16.8526 22.9991ZM16.8526 19.7398C16.6458 19.7398 16.4476 19.8219 16.3014 19.9681C16.1553 20.1143 16.0732 20.3125 16.0732 20.5192C16.0727 20.6185 16.0931 20.7168 16.133 20.8078C16.173 20.8987 16.2316 20.9802 16.3051 21.047C16.3786 21.1138 16.4653 21.1645 16.5596 21.1956C16.6539 21.2267 16.7537 21.2377 16.8526 21.2277C16.9483 21.2391 17.0455 21.2286 17.1366 21.1971C17.2278 21.1655 17.3106 21.1136 17.3788 21.0454C17.447 20.9772 17.4988 20.8944 17.5304 20.8033C17.562 20.7121 17.5725 20.615 17.5611 20.5192C17.571 20.4204 17.5601 20.3206 17.5289 20.2263C17.4978 20.1319 17.4472 20.0452 17.3804 19.9717C17.3136 19.8982 17.232 19.8396 17.1411 19.7997C17.0502 19.7597 16.9519 19.7393 16.8526 19.7398Z"
      fill="#323232"
    />
    <path
      d="M12.6725 21.3695H11.3971C11.1716 21.3695 10.9554 21.2799 10.7959 21.1204C10.6365 20.961 10.5469 20.7447 10.5469 20.5192C10.5371 20.4017 10.5518 20.2835 10.5901 20.172C10.6284 20.0606 10.6895 19.9583 10.7694 19.8717C10.8494 19.785 10.9465 19.716 11.0545 19.6689C11.1626 19.6218 11.2793 19.5977 11.3971 19.5981H12.6725C12.9168 19.5981 13.1511 19.6952 13.3238 19.8679C13.4966 20.0406 13.5936 20.2749 13.5936 20.5192C13.594 20.6371 13.5699 20.7538 13.5228 20.8618C13.4757 20.9699 13.4067 21.067 13.3201 21.1469C13.2334 21.2269 13.1312 21.288 13.0197 21.3263C12.9082 21.3646 12.79 21.3793 12.6725 21.3695Z"
      fill="#323232"
    />
  </svg>
</template>
