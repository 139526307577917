<template>
  <div class="aside-fixed-control__item">
    <a
      class="aside-fixed-control__item-control hovered"
      :class="{'aside-fixed-control__item-control--open': isActive}"
      href="#"
      @click.prevent="changeVision()"
    >
      <div v-if="item.count" class="mobile__icon_count">
        <div class="mobile__icon_count_text">{{ item.count }}</div>
      </div>
      <div class="aside-fixed-control__item-control-ico">
        <component :is="item.ico"></component>
      </div>
    </a>
    <responsive-wrapper v-if="isActive && item.component" :text="item.name" @close="changeVision()">
      <template #svg>
        <component :is="item.ico"></component>
      </template>
      <component :is="item.component" @close="isActive = false"></component>
    </responsive-wrapper>
  </div>
</template>

<script setup lang="ts">
import {type PropType, ref, useRouter} from '#imports';
import type {IControlTools} from '@/types/pages/cabinet/tools';
import ResponsiveWrapper from './wrapper.vue';

const props = defineProps({
  item: {type: Object as PropType<IControlTools>, required: true},
});

const router = useRouter();

const isActive = ref(false);

const changeVision = () => {
  if (props.item.component) {
    isActive.value = !isActive.value;
  } else {
    void router.push(props.item.url);
  }
};
</script>
