<template>
  <div class="personal__auth_wrapper">
    <div class="personal__auth personal__auth--middle">
      <ul class="personal__list">
        <li
          v-for="menuItem in menuItems"
          :key="menuItem.text"
          class="personal__elem personal__elem--flex"
        >
          <nuxt-link
            class="personal__text"
            :class="{disabled: menuItem.to == null}"
            :to="menuItem.to"
            >{{ menuItem.text }}</nuxt-link
          >
          <span v-if="menuItem.count != null" class="personal__elem-count">{{
            menuItem.count
          }}</span>
        </li>
      </ul>
      <base-button
        v-for="(action, i) in actions"
        :key="i"
        :text="action.text"
        full
        size="small"
        :url="action.to"
        :outlined="action.outlined"
        :color="action.color"
        @click="action.fn?.()"
      ></base-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import 'sass/default-layout/hovered-block.sass';

import {computed} from '#imports';
import type {RouteLocationRaw} from '#vue-router';
import BaseButton from '@/components/Base/Button.vue';
import {useAuthStore} from '@/stores/auth';
import {useBasketStore} from '@/stores/basket';
import {useCabinetGoodsStore} from '@/stores/cabinetGoods';

const props = defineProps({
  compareCount: {type: Number, required: false, default: 0},
  favouriteCount: {type: Number, required: false, default: 0},
  basketCount: {type: Number, required: false, default: 0},
  isAuth: {type: Boolean, required: true},
});

const authStore = useAuthStore();
const basketStore = useBasketStore();
const cabinetGoodsStore = useCabinetGoodsStore();

const logout = async () => {
  await authStore.logout();
  await basketStore.get();
};

interface Action {
  text: string;
  to?: RouteLocationRaw;
  outlined?: boolean;
  color?: string;
  fn?: (...args: unknown[]) => unknown;
}

const NOT_AUTH_ACTIONS: Action[] = [
  {text: 'войти', to: '/auth/', outlined: false, color: 'grey'},
  {text: 'зарегистрироваться', to: '/reg/', outlined: true},
];

const AUTH_ACTIONS: Action[] = [{text: 'выйти', to: '/auth/', outlined: false, fn: () => logout()}];

const actions = computed(() => (authStore.isAuth ? AUTH_ACTIONS : NOT_AUTH_ACTIONS));

const menuItems = computed<{text: string; to?: RouteLocationRaw; count?: number}[]>(() => [
  {text: 'Списки сравнения', to: '/compare', count: props.compareCount},
  {text: 'Корзина', to: '/basket', count: props.basketCount},
  {
    text: 'Отложенные товары',
    to: '/delayed-products',
    count: cabinetGoodsStore.getCountProductsInFavorite,
  },
  {text: 'Благотворительность'},
]);
</script>
