<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" fill="#A0A0A7" />
    <path
      d="M20.6216 7.98409C20.417 7.20682 19.8034 6.59318 19.0261 6.37841C17.625 6 12 6 12 6C12 6 6.375 6 4.96364 6.37841C4.18636 6.58295 3.58295 7.19659 3.36818 7.98409C3 9.39545 3 12.3409 3 12.3409C3 12.3409 3 15.2864 3.37841 16.6977C3.58295 17.475 4.19659 18.0886 4.97386 18.3034C6.375 18.6818 12 18.6818 12 18.6818C12 18.6818 17.625 18.6818 19.0364 18.3034C19.8136 18.0989 20.417 17.4852 20.6318 16.6977C21 15.2864 21 12.3409 21 12.3409C21 12.3409 21 9.39545 20.6216 7.98409Z"
      fill="#D9D9DC"
    />
    <path d="M10.1587 15.0207L14.8632 12.3412L10.1587 9.66162V15.0207Z" fill="#A0A0A7" />
  </svg>
</template>
