<template>
  <svg width="18" height="18" viewBox="2 2 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line
      x1="6.65625"
      y1="17.6571"
      x2="17.97"
      y2="6.34337"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
    />
    <line
      x1="6.34317"
      y1="6.34314"
      x2="17.6569"
      y2="17.6568"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>
