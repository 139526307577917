<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.8459 10.844C11.1691 10.5208 11.6931 10.5208 12.0163 10.844L15.7586 14.5862C16.0818 14.9094 16.0818 15.4334 15.7586 15.7566C15.4353 16.0798 14.9113 16.0798 14.5881 15.7566L10.8459 12.0144C10.5227 11.6912 10.5227 11.1672 10.8459 10.844Z"
      fill="#A0A0A7"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.75279 1.65521C3.93748 1.65521 1.65521 3.93748 1.65521 6.75279C1.65521 9.56811 3.93748 11.8504 6.75279 11.8504C9.56811 11.8504 11.8504 9.56811 11.8504 6.75279C11.8504 3.93748 9.56811 1.65521 6.75279 1.65521ZM0 6.75279C0 3.02333 3.02333 0 6.75279 0C10.4823 0 13.5056 3.02333 13.5056 6.75279C13.5056 10.4823 10.4823 13.5056 6.75279 13.5056C3.02333 13.5056 0 10.4823 0 6.75279Z"
      fill="#A0A0A7"
    />
  </svg>
</template>
