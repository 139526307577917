<template>
  <div class="mobile-sub-menu">
    <div class="mobile-sub-menu_relative">
      <div class="mobile-sub-menu__top">
        <button class="mobile-sub-menu__closed" type="button" @click="emit('close')">
          <close-svg></close-svg>
        </button>
        <div class="mobile-sub-menu__auth_wrapper">
          <div v-if="!isAuth" class="mobile-sub-menu__registration">
            <nuxt-link to="/reg" @click="emit('close')"> Регистрация </nuxt-link>
          </div>
          <nuxt-link
            class="mobile-sub-menu__sign-in"
            :to="isAuth ? '/cabinet/' : '/auth/'"
            @click="emit('close')"
          >
            <profile-svg id="mobile_bar_menu"></profile-svg>
            {{ isAuth ? 'Кабинет' : 'Войти' }}
          </nuxt-link>
          <base-button v-if="isAuth" text="Выход" outlined @click="logout()"></base-button>
        </div>
      </div>
      <div class="languages"></div>

      <transition name="translate-right" mode="out-in">
        <div v-if="!selectedList?.childs" class="mobile-sub-menu_wrapper scrollbar-y">
          <ul class="mobile-sub-menu__body_main">
            <li
              v-for="(elem, i) in navLinks"
              :key="i"
              class="mobile-sub-menu__elem mobile-sub-menu__elem_fat"
              tabindex="0"
              role="menuitem"
              @click.stop="showModal(elem)"
              @keydown.enter="showModal(elem)"
            >
              <component
                :is="elem.url != null ? NuxtLink : 'span'"
                class="hovered"
                :class="{disabled: !elem.url}"
                :to="elem.url ?? undefined"
                @click.stop="emit('close')"
              >
                {{ elem.name }}
              </component>
              <div v-if="elem.childs?.length" class="mobile-sub-menu__arrow hovered disabled">
                <small-arrow-svg></small-arrow-svg>
              </div>
            </li>
          </ul>
          <ul class="mobile-sub-menu__body_sub">
            <li
              v-for="(sub, i) in [...(menuData?.top || []), ...(menuData?.main || [])]"
              :key="i"
              class="mobile-sub-menu__elem mobile-sub-menu__elem_slim"
              tabindex="0"
              role="menuitem"
              @click.stop="showModal(sub)"
              @keydown.enter="showModal(sub)"
            >
              <component
                :is="sub.url != null ? NuxtLink : 'span'"
                :to="sub.url ?? undefined"
                :class="{disabled: !sub.url}"
                @click.stop="emit('close')"
              >
                {{ sub.name }}
              </component>
              <div class="mobile-sub-menu__arrow hovered" @click.stop="showModal(sub)">
                <small-arrow-svg v-if="sub.childs?.length"></small-arrow-svg>
              </div>
            </li>
          </ul>
        </div>
        <mobile-sub-menu
          v-else-if="selectedList?.childs"
          :list="selectedList"
          @close-menu="selectedList = undefined"
          @close-full-modal="emit('close')"
        ></mobile-sub-menu>
      </transition>
      <div class="mobile-sub-menu__contact_wrapper">
        <contact-info class="mobile-sub-menu__contact"></contact-info>
        <logo-svg id="sub_menu_logo" class="mobile-sub-menu__logo"></logo-svg>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import 'sass/default-layout/mobile-menu.scss';
import 'sass/default-layout/languages.scss';
import 'sass/utilites.scss';

import {NuxtLink} from '#components';
import {type PropType, computed, ref} from '#imports';
import BaseButton from '@/components/Base/Button.vue';
import {useAuthStore} from '@/stores/auth';
import {useRootStore} from '@/stores/root';
import type {IChild2Response, IMainResponse} from '@/types/store/store';
import CloseSvg from 'svg/header/close.vue';
import ProfileSvg from 'svg/header/profile.vue';
import SmallArrowSvg from 'svg/header/smallArrow.vue';
import LogoSvg from 'svg/logo.vue';
import contactInfo from '../contactInfo.vue';
import mobileSubMenu from './subMenu.vue';

const props = defineProps({
  navLinks: {type: Array as PropType<IMainResponse[]>, required: true},
  width: {type: Number, required: false},
});

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'show-modal', value: IChild2Response[]): void;
}>();

const authStore = useAuthStore();
const rootStore = useRootStore();

const isAuth = computed(() => authStore.isAuth);

const menuData = computed(() => rootStore.menuData);

const selectedList = ref<IMainResponse>();

const showModal = (list: IMainResponse) => {
  if (!list.childs?.length) {
    return;
  }
  if ((props.width || 0) > 524) {
    emit('show-modal', list.childs);
    return;
  }
  selectedList.value = list;
};

const logout = async () => {
  await authStore.logout();
  emit('close');
};
</script>
