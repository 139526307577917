<template>
  <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.5 10.5C8.43025 10.5 10 8.93025 10 7C10 5.06975 8.43025 3.5 6.5 3.5C4.56975 3.5 3 5.06975 3 7C3 8.93025 4.56975 10.5 6.5 10.5ZM6.5 5.25C7.46513 5.25 8.25 6.03487 8.25 7C8.25 7.96513 7.46513 8.75 6.5 8.75C5.53487 8.75 4.75 7.96513 4.75 7C4.75 6.03487 5.53487 5.25 6.5 5.25Z"
      fill="#0B2835"
    />
    <path
      d="M6.02876 16.3513C6.16627 16.448 6.33102 16.5 6.5 16.5C6.66898 16.5 6.83373 16.448 6.97125 16.3513C7.21824 16.1793 13.0235 12.0521 12.9999 6.90005C12.9999 3.37122 10.0839 0.5 6.5 0.5C2.9161 0.5 7.15299e-05 3.37122 7.15299e-05 6.89605C-0.0234907 12.0521 5.78176 16.1793 6.02876 16.3513ZM6.5 2.10001C9.18853 2.10001 11.3749 4.25283 11.3749 6.90405C11.392 10.4545 7.80974 13.6425 6.5 14.6881C5.19108 13.6417 1.60799 10.4529 1.62505 6.90005C1.62505 4.25283 3.81147 2.10001 6.5 2.10001Z"
      fill="#0B2835"
    />
  </svg>
</template>
