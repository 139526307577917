<template>
  <base-modal v-model="isModal">
    <div class="contact-modal">
      <h3 class="h3">Заказать обратный звонок</h3>
      <div v-if="!successMessage" class="contact-modal__content">
        <base-input label="Имя*" :error="error.name">
          <input
            v-model="contactForm.name"
            type="text"
            class="base-form__input"
            :class="{'base-form__input-invalid': error.name}"
            placeholder="Введите ваше имя"
          />
        </base-input>
        <base-input label="Телефон*" :error="error.phone">
          <input
            v-model="contactForm.phone"
            v-maska:[phoneMask]
            type="tel"
            class="base-form__input"
            :class="{'base-form__input-invalid': error.phone}"
            placeholder="+7 (___)-___-__-__"
            inputmode="tel"
          />
        </base-input>
        <base-input label="Комментарий (необязательно)">
          <textarea
            v-model="contactForm.text"
            class="base-form__textarea"
            placeholder="Если немного напишете о том, что вас интересует – у нас будет более подготовленный и продуктивный разговор."
          ></textarea>
        </base-input>
        <contact-privacy v-model="isChecked"></contact-privacy>
        <p>
          Менеджер свяжется с вами в течение 15-20 минут, с 09:00 до 18:00 по московскому времени.
        </p>
        <base-button
          :class="{disabled: !isChecked}"
          text="Отправить"
          @click="isChecked && sendForm()"
        ></base-button>
      </div>
      <p v-else class="base-form__text-success">
        {{ successMessage }}
      </p>
    </div>
  </base-modal>
</template>

<script setup lang="ts">
import 'sass/contacts/modal.sass';
import 'sass/typography.scss';

import {computed, ref, useRoute} from '#imports';
import BaseButton from '@/components/Base/Button.vue';
import BaseInput from '@/components/Base/input.vue';
import BaseModal from '@/components/Base/modal.vue';
import ContactPrivacy from '@/components/contact/privacy.vue';
import {
  useCorrectingForm,
  useIsValidForm,
  useValidateName,
  useValidatePhone,
} from '@/composables/useForm';
import {useContactStore} from '@/stores/contact';
import type {IContactCallForm} from '@/types/pages/contact';
import * as masks from '@/utils/masks';

const props = defineProps({
  modelValue: {type: Boolean, required: false, default: false},
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

const {phoneMask} = masks;

const route = useRoute();

const contactStore = useContactStore();

const isChecked = ref(false);

const isModal = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit('update:modelValue', val);
  },
});

const error = ref<{
  name: string | undefined;
  phone: string | undefined;
}>({
  name: '',
  phone: '',
});

const contactForm = ref<IContactCallForm>({
  name: '',
  phone: '',
  text: '',
  url: route.fullPath,
});

const successMessage = ref('');

const isValidForm = computed(() => useIsValidForm(error.value));

const clearContactForm = () => {
  contactForm.value.name = '';
  contactForm.value.phone = '';
};

const clearErrors = () => {
  setTimeout(() => {
    error.value.name = '';
    error.value.phone = '';
  }, 3000);
};

const validateForm = (form: IContactCallForm) => {
  error.value.name = useValidateName(form.name);
  error.value.phone = useValidatePhone(form.phone);
  clearErrors();
};

const sendForm = async () => {
  const form = useCorrectingForm(JSON.parse(JSON.stringify(contactForm.value)) as IContactCallForm);
  validateForm(form);
  if (isValidForm.value) {
    await contactStore.sendModalForm(form);
    clearContactForm();
    successMessage.value = 'Ожидайте обратный звонок';
  }
};
</script>

<style lang="scss" scoped>
.h3 {
  text-align: center;
}
</style>
