<template>
  <header class="header">
    <layout-header-top></layout-header-top>
    <div class="container">
      <div class="header__main">
        <div class="header__logo">
          <header-title></header-title>
        </div>
        <layout-header-main-search
          v-model="searchText"
          @search-push="searchPush()"
        ></layout-header-main-search>
        <layout-header-main-cart></layout-header-main-cart>
      </div>
    </div>
    <layout-header-bottom @search-push="searchPush()"></layout-header-bottom>
  </header>
</template>

<script setup lang="ts">
import 'sass/default-layout/header.scss';
import 'sass/default-layout/cart.scss';
import 'sass/default-layout/personal.scss';
import 'sass/default-layout/about.scss';
import 'sass/default-layout/sub-menu.scss';
import 'sass/default-layout/languages.scss';
import 'sass/index.scss';
import 'sass/vue-animations.scss';
import 'sass/utilites.scss';
import 'sass/aside/collapsible.sass';

import {onMounted, ref, useRouter} from '#imports';
import {SEARCH} from '@/composables/useURL';
import {useAuthStore} from '@/stores/auth';
import {useBasketStore} from '@/stores/basket';
import {useCabinetGoodsStore} from '@/stores/cabinetGoods';
import {useCompareStore} from '@/stores/compare';
import HeaderTitle from '../header-title.vue';
import LayoutHeaderBottom from './bottom.vue';
import LayoutHeaderMainCart from './main/cart/cart.vue';
import LayoutHeaderMainSearch from './main/search.vue';
import LayoutHeaderTop from './top/index.vue';

const router = useRouter();
const basketStore = useBasketStore();
const compareStore = useCompareStore();
const cabinetGoodsStore = useCabinetGoodsStore();
const authStore = useAuthStore();

const searchText = ref('');

onMounted(async () => {
  await basketStore.get();
  await compareStore.get();
  await cabinetGoodsStore.getProductsInFavorite();
  await authStore.checkAuth();
});

const searchPush = (): void => {
  if (searchText.value.length) {
    void router.push({path: '/search', query: {text: searchText.value}});
  }
};
</script>
