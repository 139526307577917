<template>
  <div class="header__cart">
    <cart-item text="Корзина" :count="smallInfo.count || 0" :url="BASKET">
      <template #icon>
        <cart-svg></cart-svg>
      </template>
      <template #triangle>
        <span v-if="productsCount" class="header__cart-drop-triangle"></span>
      </template>
      <template #hovered>
        <layout-hovered-cart-drop v-if="productsCount"></layout-hovered-cart-drop>
      </template>
    </cart-item>

    <cart-item text="Избранное" :count="favouriteCount" :url="favoriteUrl">
      <template #icon>
        <favourite-svg></favourite-svg>
      </template>
    </cart-item>

    <cart-item text="Сравнение" :count="compareCount" :url="COMPARE" :svg="CompareSvg">
      <template #icon>
        <compare-svg></compare-svg>
      </template>
    </cart-item>

    <base-button :url="isEmptyBasket ? undefined : ORDER" text="Оформить"></base-button>
  </div>
</template>

<script setup lang="ts">
import {computed} from '#imports';
import BaseButton from '@/components/Base/Button.vue';
import {BASKET, COMPARE, FAVOURITE, FAVOURITE_NOT_AUTH, ORDER} from '@/composables/useURL';
import {useAuthStore} from '@/stores/auth';
import {useBasketStore} from '@/stores/basket';
import {useCabinetGoodsStore} from '@/stores/cabinetGoods';
import {useCompareStore} from '@/stores/compare';
import CartSvg from 'svg/header/cart.vue';
import CompareSvg from 'svg/header/compare.vue';
import FavouriteSvg from 'svg/header/favourite.vue';
import LayoutHoveredCartDrop from '../../../hovered/cartDrop/index.vue';
import CartItem from './item.vue';

const basketStore = useBasketStore();
const authStore = useAuthStore();
const compareStore = useCompareStore();
const cabinetGoodsStore = useCabinetGoodsStore();

const smallInfo = computed(() => basketStore.smallInfo);
const isAuth = computed(() => authStore.isAuth);

const productsCount = computed(() => basketStore.getSmallInfoProductsCount);
const isEmptyBasket = computed<boolean>(() => !smallInfo.value.count);
const compareCount = computed(() => compareStore.ids.length);
const favouriteCount = computed(() => cabinetGoodsStore.productsInFavorite.length);

const favoriteUrl = computed<string>(() => (isAuth.value ? FAVOURITE : FAVOURITE_NOT_AUTH));
</script>
