<template>
  <div class="header__cart-drop-item">
    <nuxt-link :to="product.url" class="header__cart-drop-item-img hovered">
      <img :src="product.img" :alt="product.name" />
    </nuxt-link>
    <div class="header__cart-drop-item-content">
      <div class="header__cart-drop-item-descr">
        <nuxt-link :to="product.url" class="header__cart-drop-item-title hovered">{{
          product.name
        }}</nuxt-link>
        <div class="header__cart-drop-item-info">
          <ui-price
            class="header__cart-drop-item-price"
            :price="priceShown ? resultPrice : 0"
          ></ui-price>
          <span v-if="isServicePrice" class="header__cart-drop-item-price">
            доп.
            <ui-price :price="product.servicePrice"></ui-price>
          </span>
          <div v-if="product.price && product.bonuses" class="header__cart-drop-item-bonus">
            <span class="small-product__bonus">
              +{{ useSpacedPrice(product.bonuses * product.count) }} ТМ
            </span>
          </div>
        </div>
      </div>
      <div class="header__cart-drop-item-count">{{ product.count }} шт.</div>
      <div class="header__cart-drop-item-control">
        <div
          class="header__cart-drop-item-control-elm hovered"
          @click="basketStore.delete(product.id)"
        >
          <close-svg></close-svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {type PropType, computed, useRuntimeConfig} from '#imports';
import UiPrice from '@/components/UI/price.vue';
import {useSpacedPrice} from '@/composables/useUtils';
import {useBasketStore} from '@/stores/basket';
import type {IProductBasketZeroResponse} from '@/types/store/basket';
import CloseSvg from 'svg/close.vue';

const props = defineProps({
  product: {type: Object as PropType<IProductBasketZeroResponse>, required: true},
});

const basketStore = useBasketStore();

const isServicePrice = computed(() => Boolean(props.product.servicePrice) && !props.product.price);

const hasPrice = computed(() => props.product.price > 0);

const realProductPrice = computed(() => props.product.price * props.product.count);
const realServicePrice = computed(() => props.product.servicePrice);

const strongPrice = computed(() => realProductPrice.value + realServicePrice.value);

const resultPrice = computed(() => (hasPrice.value ? strongPrice.value : realProductPrice.value));

const config = useRuntimeConfig();

const priceShown = computed(() => hasPrice.value && !config.public.hidePrices);
</script>
