<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line
      x1="8.22183"
      y1="9.22168"
      x2="23.7782"
      y2="24.778"
      stroke="#FF6245"
      stroke-width="2"
      stroke-linecap="round"
    />
    <line
      x1="23.7781"
      y1="9.22183"
      x2="8.22173"
      y2="24.7782"
      stroke="#FF6245"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>
