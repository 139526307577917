<template>
  <div class="personal__auth_wrapper">
    <div class="personal__auth personal__auth--middle">
      <div class="aside-collapsible_wrapper">
        <auth-item v-for="item in dropList" :key="item.id" :item="item"></auth-item>
      </div>
      <base-button text="Выйти" full size="small" color="grey" @click="logout()"></base-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import {type PropType} from '#imports';
import BaseButton from '@/components/Base/Button.vue';
import {useAuthStore} from '@/stores/auth';
import AuthItem from './item.vue';

defineProps({
  dropList: {
    type: Array as PropType<
      {
        name: string;
        isOpen: boolean;
        id: number;
        childs: {
          url: string;
          name: string;
          id: number;
        }[];
      }[]
    >,
    required: true,
  },
});

const {logout} = useAuthStore();
</script>
