<template>
  <div class="language">
    <div class="language__elem language__elem_selected">
      {{ activeLanguage }}
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref} from '#imports';

const languages = ['RU', 'EN', 'CH', 'DE'];
const activeLanguage = ref(languages[0]);
</script>
