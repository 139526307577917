<template>
  <div class="main_container">
    <contact-call-modal v-model="showContactModal"></contact-call-modal>
    <div>
      <div class="page not-print">
        <default-layout-header></default-layout-header>
      </div>
      <slot></slot>
      <cabinet-tools-responsive v-if="isAuth" class="is-tablet-hide"></cabinet-tools-responsive>
    </div>
    <transition>
      <a
        v-if="isScroller"
        class="main_container__scroller hovered"
        href="#"
        @click.prevent="scrollTop()"
      >
        <arrow-svg></arrow-svg>
      </a>
    </transition>
    <base-footer class="not-print"></base-footer>
    <ui-notification></ui-notification>
  </div>
</template>

<script setup lang="ts">
import 'sass/index.scss';
import 'sass/fonts.scss';
import 'sass/reset.scss';

import {useEventListener} from '@vueuse/core';
import {computed, onMounted, ref, useAsyncData} from '#imports';
import UiNotification from '@/components/UI/notification.vue';
import CabinetToolsResponsive from '@/components/cabinet/tools/responsive/index.vue';
import ContactCallModal from '@/components/contact/callModal.vue';
import BaseFooter from '@/components/default-layout/BaseFooter.vue';
import DefaultLayoutHeader from '@/components/default-layout/header/index.vue';
import {useAuthStore} from '@/stores/auth';
import {useContactStore} from '@/stores/contact';
import {useRootStore} from '@/stores/root';
import ArrowSvg from 'svg/scrollArrow.vue';

const {fetchMenuData} = useRootStore();
const contactStore = useContactStore();
const authStore = useAuthStore();

void useAsyncData('menuData', fetchMenuData);

const isAuth = computed(() => authStore.isAuth);
const isScroller = ref(false);

const showContactModal = computed({
  get() {
    return contactStore.isContactModal;
  },
  set(val) {
    contactStore.isContactModal = val;
  },
});

const checkScroll = () => {
  if (window.scrollY && window.scrollY > 200 && !isScroller.value) {
    isScroller.value = true;
  } else if (window.scrollY < 200 && isScroller.value) {
    isScroller.value = false;
  }
};

onMounted(() => {
  useEventListener(window, 'scroll', checkScroll);
  checkScroll();
});

const scrollTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};
</script>

<style scoped>
.page {
  position: relative;
}
</style>
