<template>
  <header-menu
    v-model="selectedSubMenu"
    :width="width"
    :nav-links="navLinks"
    :small-info="smallInfo"
    :compare-count="compareCount"
    @search-push="emit('search-push')"
  ></header-menu>
  <teleport to="body">
    <div v-if="selectedSubMenu.list?.length" class="header-bg"></div>
  </teleport>
  <transition>
    <layout-header-scrolled-bar
      v-show="scrollY > 134"
      :small-info="smallInfo"
      :nav-links="navLinks"
      :compare-count="compareCount"
    ></layout-header-scrolled-bar>
  </transition>
</template>

<script setup lang="ts">
import {useWindowScroll, useWindowSize} from '@vueuse/core';
import {computed, ref} from '#imports';
import {useBasketStore} from '@/stores/basket';
import {useCompareStore} from '@/stores/compare';
import {useRootStore} from '@/stores/root';
import type {IChild2Response} from '@/types/store/store';
import HeaderMenu from './menu/index.vue';
import LayoutHeaderScrolledBar from './scrolledBar.vue';

interface Menu {
  id: number;
  list: IChild2Response[];
}

defineProps({
  compareCount: {type: Number, required: false},
});

const emit = defineEmits<{
  (e: 'search-push'): void;
}>();

const compareStore = useCompareStore();
const basketStore = useBasketStore();
const rootStore = useRootStore();

const {width} = useWindowSize({
  initialWidth: 0,
});
const {y: scrollY} = useWindowScroll();

// TODO
const selectedSubMenu = ref<Menu>({});

// TODO
// eslint-disable-next-line vue/no-dupe-keys
const compareCount = computed<number>(() => compareStore.ids.length);
const navLinks = computed(() => rootStore.menuData?.main || []);

const smallInfo = computed(() => basketStore.smallInfo);
</script>
