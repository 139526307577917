<template>
  <teleport to="body">
    <transition name="notification">
      <div v-if="isShow" class="notification">
        <div class="notification__img-wrapper">
          <div class="notification__img">
            <img
              :src="basket?.products[basket.products.length - 1]?.img"
              :alt="basket?.products[basket.products.length - 1]?.name"
            />
          </div>
          <div class="notification__product">
            <div class="notification__product-id">
              id: {{ basket?.products[basket.products.length - 1]?.code }}
            </div>
            <div class="notification__product-title">
              {{ basket?.products[basket.products.length - 1]?.name }}
            </div>
          </div>
          <div class="notification__text">Товар в корзине!</div>
        </div>
        <div class="notification__btn">
          <base-button url="/basket" text="Перейти" color="outline-blue-2">
            <template #leftIcon>
              <span class="notification__icon">
                <cart-icon></cart-icon>
              </span>
            </template>
          </base-button>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script setup lang="ts">
import 'sass/notification.scss';

import {computed, ref, watch} from '#imports';
import BaseButton from '@/components/Base/Button.vue';
import CartIcon from '@/components/svg/plug/basket.vue';
import {useBasketStore} from '@/stores/basket';
import type {IGetBasketZeroResponse} from '@/types/store/basket';

const basketStore = useBasketStore();

const isShow = ref(false);
const basket = computed<IGetBasketZeroResponse>(() => basketStore.smallInfo);
const basketLength = computed(() => basketStore.localProducts);

watch(
  () => basketLength.value,
  () => {
    if (isShow.value) {
      isShow.value = false;
    }
    setTimeout(() => {
      isShow.value = true;
    }, 150);
  },
  {deep: true},
);
</script>
