import ciscoImg from 'images/partners/cisco.png';
import CommuniGateImg from 'images/partners/communiGate.png';
import dellImg from 'images/partners/dell.png';
import hpImg from 'images/partners/hp.png';
import lenovoImg from 'images/partners/lenovo.png';

export const partnersList = [
  {id: 1, img: hpImg, name: 'HP', url: '/proizvoditeli/hp/'},
  {id: 2, img: dellImg, name: 'Dell', url: '/proizvoditeli/dell/'},
  {id: 3, img: lenovoImg, name: 'Lenovo', url: '/proizvoditeli/lenovo/'},
  {id: 4, img: ciscoImg, name: 'Cisco', url: '/proizvoditeli/cisco/'},
  {
    id: 5,
    img: CommuniGateImg,
    name: 'CommuniGate',
    url: '/proizvoditeli/communigate-systems/',
  },
];
