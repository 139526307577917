<template>
  <div class="mobile_modal_wrapper">
    <div class="modal-menu_header">
      <div class="container">
        <div class="modal-menu_header_wrapper">
          <div class="modal-menu__back" @click="closeMenu()">
            <small-arrow-svg left color="#FFFFFF"></small-arrow-svg>
            Назад
          </div>
          <label
            style="width: 100%; max-width: 513px"
            class="header__search header__search_mobile"
            :class="{header__search_focused: isMobileSearchFocused}"
            for="search"
          >
            <input
              id="search"
              v-model="searchText"
              type="search"
              placeholder="Поиск по названию, конфигурации или parts numbers"
              @focus="isMobileSearchFocused = true"
              @blur="isMobileSearchFocused = false"
              @keydown.enter="searchPush()"
            />
            <mobile-search-svg id="mobile_search_sub" @click="searchPush()"></mobile-search-svg>
          </label>
        </div>
      </div>
    </div>
    <transition :name="`translate-${animationDirection}`">
      <mobile-sub-menu-element
        v-if="isAnimation"
        :list="selectedList[listLength]"
        :is-main="isMain"
        @select-item="selectItem($event)"
        @close-full-modal="emit('close-full-modal')"
      ></mobile-sub-menu-element>
    </transition>
  </div>
</template>

<script setup lang="ts">
import {type PropType, computed, onMounted, ref, useRouter, watch} from '#imports';
import type {IMainResponse} from '@/types/store/store';
import MobileSearchSvg from 'svg/header/mobileSearch.vue';
import SmallArrowSvg from 'svg/header/smallArrow.vue';
import MobileSubMenuElement from './subMenuElem.vue';

const props = defineProps({
  list: {type: Object as PropType<IMainResponse>, required: true},
});

const emit = defineEmits<{
  (e: 'close-menu'): void;
  (e: 'close-full-modal'): void;
}>();

const router = useRouter();

const isMobileSearchFocused = ref(false);
const selectedList = ref<IMainResponse[]>([]);
const isAnimation = ref(true);
const animationDirection = ref('right');
const searchText = ref('');

const selectItem = (list: IMainResponse) => {
  selectedList.value.push(list);
};

onMounted(() => {
  selectItem(props.list);
});

const closeMenu = () => {
  if (selectedList.value.length > 1) {
    selectedList.value.pop();
    return;
  }
  emit('close-menu');
};

const listLength = computed(() => selectedList.value.length - 1);

watch(
  listLength,
  (newList, oldList) => {
    animationDirection.value = oldList < newList ? 'right' : 'left';

    isAnimation.value = false;
    setTimeout(() => {
      isAnimation.value = true;
    }, 0);
  },
  {deep: true},
);
const isMain = computed(() => selectedList.value[0] === selectedList.value[listLength.value]);

const searchPush = () => {
  if (searchText.value.length) {
    void router.push({path: '/search', query: {text: searchText.value}});
    emit('close-full-modal');
  }
};
</script>
