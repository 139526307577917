<template>
  <div class="header__menu_mobile_wrapper">
    <transition>
      <a
        v-if="isMobileSubMenu"
        href="#"
        class="header_bg"
        @click.prevent="isMobileSubMenu = false"
      ></a>
    </transition>
    <div class="container">
      <!-- TODO: вызывает ошибку гидрации на всех страницах -->
      <client-only>
        <div class="header__menu_mobile">
          <div class="header__bar">
            <a class="header__bar_svg" href="#">
              <bar-svg @click="isMobileSubMenu = true"></bar-svg>
            </a>
            <transition name="translate-left">
              <mobile-menu
                v-if="isMobileSubMenu"
                :nav-links="navLinks"
                @show-modal="selectMenu($event)"
                @close="isMobileSubMenu = false"
              ></mobile-menu>
            </transition>
          </div>
          <transition :duration="isMobileSearch ? 1 : 1200">
            <header-title
              v-if="
                width > 0 &&
                ((width < MOBILE_LAYOUT_THRESHOLD && !isMobileSearch) ||
                  width > MOBILE_LAYOUT_THRESHOLD)
              "
            ></header-title>
          </transition>
          <form class="header__menu_search_wrapper" @submit.prevent="emit('search-push')">
            <transition :duration="!isMobileSearch ? 1 : 1200">
              <label v-if="isShowSearch" class="header__search header__search_mobile" for="search">
                <input
                  id="search"
                  ref="mobileSearch"
                  v-model="searchText"
                  type="search"
                  placeholder="Поиск по названию, конфигурации или parts numbers"
                  @keydown.enter="emit('search-push')"
                />
                <mobile-search-svg
                  id="search_main_mobile"
                  style="margin-right: 5px"
                  @click="emit('search-push')"
                ></mobile-search-svg>
              </label>
            </transition>
            <layout-header-main-fast-search
              v-if="isFastSearch && isShowSearch"
              v-model="searchText"
              @close="hideMobileSearch()"
            ></layout-header-main-fast-search>
          </form>
          <div v-if="!isMobileSearch" class="header__icon_mobile">
            <mobile-search-svg id="search_mobile_1" @click="showMobileSearch()"></mobile-search-svg>
          </div>
          <a v-else class="header__text-closed" href="#" @click.prevent="hideMobileSearch()"
            >Отмена</a
          >
        </div>
      </client-only>
    </div>
    <client-only>
      <layout-mobile-bar></layout-mobile-bar>
    </client-only>
  </div>
</template>

<script setup lang="ts">
import 'sass/default-layout/header.scss';
import 'sass/vue-animations.scss';

import {type PropType, computed, onMounted, ref, watch} from '#imports';
import scrollLock from '@/composables/useScrollLock';
import type {IChild2Response, IMainResponse} from '@/types/store/store';
import BarSvg from 'svg/header/bar.vue';
import MobileSearchSvg from 'svg/header/mobileSearch.vue';
import LayoutHeaderMainFastSearch from '../header/main/fastSearch/index.vue';
import HeaderTitle from '../header-title.vue';
import LayoutMobileBar from './bar.vue';
import MobileMenu from './menu.vue';

const props = defineProps({
  width: {type: Number, required: true},
  navLinks: {type: Array as PropType<IMainResponse[]>, required: true},
});

const emit = defineEmits<{
  (e: 'show-sub-menu', value: IChild2Response[]): void;
  (e: 'search-push'): void;
  (e: 'update:searchText', value: string): void;
}>();

const MOBILE_LAYOUT_THRESHOLD = 475;

const body = ref<HTMLBodyElement | null>(null);

onMounted(() => {
  body.value = document.querySelector('body');
});

const mobileSearch = ref<HTMLInputElement | null>();
const isMobileSearch = ref(false);
const isMobileSubMenu = ref(false);
const searchText = ref('');

const isShowSearch = computed<boolean>(() => isMobileSearch.value || props.width > 640);

const isFastSearch = computed(() => {
  return Boolean(searchText.value.length) && isShowSearch.value;
});

watch(isMobileSubMenu, () => {
  if (!body.value) {
    return;
  }

  if (isMobileSubMenu.value) {
    scrollLock.enable(body.value);
    return;
  }
  scrollLock.disable(body.value);
});
const selectMenu = (list: IChild2Response[]): void => {
  emit('show-sub-menu', list);
};

const showMobileSearch = (): void => {
  if (isMobileSearch.value) {
    isMobileSearch.value = false;
  } else {
    isMobileSearch.value = true;
    setTimeout(() => {
      mobileSearch.value?.focus();
    }, 0);
  }
};

const hideMobileSearch = (): void => {
  isMobileSearch.value = false;
};
</script>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
