<template>
  <div class="header__cart-drop">
    <div class="header__cart-drop-content scrollbar-y">
      <product-comp v-for="item in products" :key="item.id" :product="item"></product-comp>
    </div>
    <div class="header__cart-drop-footer">
      <div class="basket-final-prices">
        <div class="basket-final-prices__row">
          <div class="basket-final-prices__col">
            <div class="basket-final-prices__medium text-medium">
              Итого
              <span v-if="zeroProducts.length">*</span>
            </div>
          </div>

          <div class="basket-final-prices__col">
            <ui-price
              id="resultPriceBasketVal"
              class="basket-final-prices__big text-medium"
              :is-spaced="false"
              :price="resultPrice"
            ></ui-price>
          </div>
        </div>
        <div class="basket-final-prices__row">
          <div v-if="zeroProducts.length" class="basket-final-prices__col">
            <span class="text-medium">
              *не вкл. в стоим. {{ zeroProducts.length }}
              {{ nounBasedOnCount('позици', zeroProducts.length, 'я', 'и', 'й', 'я') }}
            </span>
          </div>
          <div class="basket-final-prices__col basket-final-prices__col--end">
            <div class="small-product__bonus">
              <span class="text-medium">
                +
                <span id="resultBonusesVal">
                  {{ bonuses }}
                </span>
                ТМ
              </span>
              -бонусов
            </div>
          </div>
        </div>
      </div>

      <div class="ordering-page__aside-item-inner">
        <div class="basket-page-control-btn">
          <base-button text="Оформить" :url="ORDER"></base-button>
        </div>
        <div class="basket-page-control-btn">
          <base-button text="Перейти в корзину" color="outline" :url="BASKET"></base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, watch} from '#imports';
import BaseButton from '@/components/Base/Button.vue';
import UiPrice from '@/components/UI/price.vue';
import {BASKET, ORDER} from '@/composables/useURL';
import {useAnimateNumber, useSpacedPrice} from '@/composables/useUtils';
import {useBasketStore} from '@/stores/basket';
import type {IProductBasketZeroResponse} from '@/types/store/basket';
import {nounBasedOnCount} from '@/utils/string';
import ProductComp from './product.vue';

const basketStore = useBasketStore();

const smallInfo = computed(() => basketStore.smallInfo);

const products = computed(() => smallInfo.value.products);

const bonuses = computed(() =>
  useSpacedPrice(products.value.reduce((prev: number, el) => prev + el.bonuses * el.count, 0)),
);

const zeroProducts = computed<IProductBasketZeroResponse[]>(() =>
  products.value.filter((el: IProductBasketZeroResponse) => !el.price),
);

const resultPrice = computed<number>(() => basketStore.resultPrice);
watch(resultPrice, (next, prev) => {
  useAnimateNumber(document.querySelector('#resultPriceBasketVal'), prev, next, 1000);
});
</script>
