<template>
  <div class="aside-collapsible__col">
    <div class="aside-collapsible__title">
      {{ item.name }}
      <base-rotating-arrow
        class="aside-collapsible__arrow"
        :is-open="isOpen"
        @click="isOpen = !isOpen"
      ></base-rotating-arrow>
    </div>
    <transition-group
      name="expand"
      @enter="useEnter($event)"
      @after-enter="useAfterEnter($event)"
      @leave="useLeave($event)"
    >
      <ul v-if="isOpen" class="aside-collapsible__items">
        <li
          v-for="child in item.childs"
          :key="child.key"
          class="aside-collapsible__item"
          :class="{disabled: !child.url}"
        >
          <nuxt-link :to="child.url" class="link-blue">
            {{ child.name }}
          </nuxt-link>
        </li>
      </ul>
    </transition-group>
  </div>
</template>

<script setup lang="ts">
import {type PropType, onMounted, ref} from '#imports';
import BaseRotatingArrow from '@/components/Base/rotatingArrow.vue';
import {useAfterEnter, useEnter, useLeave} from '@/composables/useAnimations';

const props = defineProps({
  item: {
    type: Object as PropType<{
      name: string;
      isOpen: boolean;
      id: number;
      childs: {
        url: string;
        name: string;
        id: number;
      }[];
    }>,
    required: true,
  },
});

const isOpen = ref(false);

onMounted(() => {
  if (props.item.id === 1) {
    isOpen.value = true;
  }
});
</script>
