<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" fill="#A0A0A7" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.633 7.60943C18.7437 7.25843 18.633 7 18.103 7H16.3531C15.9077 7 15.7024 7.22307 15.5911 7.46929C15.5911 7.46929 14.7011 9.52386 13.4404 10.8584C13.0325 11.2454 12.8471 11.3682 12.6245 11.3682C12.5131 11.3682 12.3458 11.2454 12.3458 10.8938V7.60943C12.3458 7.18771 12.2231 7 11.8525 7H9.10054C8.82254 7 8.65521 7.19543 8.65521 7.38121C8.65521 7.78043 9.28587 7.873 9.35053 8.99671V11.4383C9.35053 11.9738 9.24854 12.0709 9.02587 12.0709C8.43255 12.0709 6.98925 10.0066 6.1326 7.64479C5.9666 7.18514 5.79861 7 5.35128 7H3.59999C3.1 7 3 7.22307 3 7.46929C3 7.90771 3.59332 10.0857 5.76328 12.9664C7.20991 14.9335 9.24654 16 11.1018 16C12.2145 16 12.3518 15.7634 12.3518 15.3552V13.8683C12.3518 13.3945 12.4571 13.3 12.8098 13.3C13.0698 13.3 13.5144 13.4234 14.5531 14.3716C15.7397 15.496 15.9351 16 16.603 16H18.353C18.853 16 19.1037 15.7634 18.9597 15.2954C18.801 14.83 18.2343 14.1544 17.483 13.3527C17.075 12.8963 16.4631 12.4045 16.2771 12.1583C16.0177 11.8426 16.0917 11.7019 16.2771 11.4209C16.2771 11.4209 18.4103 8.57564 18.6323 7.60943H18.633Z"
      fill="#D9D9DC"
    />
  </svg>
</template>
