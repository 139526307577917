<template>
  <div class="mobile__navbar">
    <div class="container">
      <div class="mobile__icons">
        <nuxt-link class="mobile__icon" :to="CATALOG">
          <catalog-svg></catalog-svg>
          <div class="mobile__text">Каталог</div>
        </nuxt-link>
        <nuxt-link class="mobile__icon" :to="isAuth ? FAVOURITE : FAVOURITE_NOT_AUTH">
          <favourite-svg></favourite-svg>
          <div v-if="favouriteCount" class="mobile__icon_count">
            <div class="mobile__icon_count_text">{{ favouriteCount }}</div>
          </div>
          <div class="mobile__text">Избранное</div>
        </nuxt-link>
        <nuxt-link class="mobile__icon" :to="isAuth ? CABINET : LOGIN">
          <profile-svg></profile-svg>
          <div class="mobile__text">{{ isAuth ? 'Кабинет' : 'Вход' }}</div>
        </nuxt-link>
        <nuxt-link class="mobile__icon" :to="COMPARE">
          <compare-svg></compare-svg>
          <div v-if="compareCount" class="mobile__icon_count">
            <div class="mobile__icon_count_text">{{ compareCount }}</div>
          </div>
          <div class="mobile__text">Сравнение</div>
        </nuxt-link>
        <nuxt-link class="mobile__icon" :to="BASKET">
          <cart-svg></cart-svg>
          <div v-if="cartCount" class="mobile__icon_count">
            <div class="mobile__icon_count_text">{{ cartCount }}</div>
          </div>
          <div class="mobile__text">Корзина</div>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import 'sass/default-layout/mobile.scss';

import {computed} from '#imports';
import {
  BASKET,
  CABINET,
  CATALOG,
  COMPARE,
  FAVOURITE,
  FAVOURITE_NOT_AUTH,
  LOGIN,
} from '@/composables/useURL';
import {useAuthStore} from '@/stores/auth';
import {useBasketStore} from '@/stores/basket';
import {useCabinetGoodsStore} from '@/stores/cabinetGoods';
import {useCompareStore} from '@/stores/compare';
import CartSvg from 'svg/header/cart.vue';
import CatalogSvg from 'svg/header/catalog.vue';
import CompareSvg from 'svg/header/compare.vue';
import FavouriteSvg from 'svg/header/favourite.vue';
import ProfileSvg from 'svg/header/profile.vue';

const authStore = useAuthStore();
const basketStore = useBasketStore();
const compareStore = useCompareStore();
const cabinetGoodsStore = useCabinetGoodsStore();

const isAuth = computed(() => authStore.isAuth);

const cartCount = computed(() => basketStore.smallInfo.count);
const compareCount = computed(() => compareStore.ids.length);
const favouriteCount = computed(() => cabinetGoodsStore.productsInFavorite.length);
</script>
