<template>
  <nuxt-link to="/" class="logo">
    <logo-svg class="logo__svg"></logo-svg>
    <component :is="isMainPage ? 'h1' : 'div'" class="logo__content">
      <div class="logo__title">Технологии и Машины</div>
      <div class="logo__text font-normal">Всё для ИТ-проектов</div>
    </component>
  </nuxt-link>
</template>

<script setup lang="ts">
import {computed, useRoute} from '#imports';
import LogoSvg from 'svg/logo.vue';

const router = useRoute();

const isMainPage = computed(() => router.path === '/');
</script>
