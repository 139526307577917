<template>
  <div class="header__numbers numbers">
    <div class="numbers__phone">
      <a class="hovered" :href="`tel:${COMPANY.phoneNumber}`">
        {{ COMPANY.phoneNumberFormatted }}
      </a>
    </div>
    <span
      class="header__call"
      role="link"
      tabindex="0"
      @click="requestCallBack()"
      @keydown.enter="requestCallBack()"
    >
      Обратный звонок
    </span>
  </div>
</template>

<script setup lang="ts">
import {COMPANY} from '@/data/constants';
import {useContactStore} from '@/stores/contact';

const contactStore = useContactStore();

const requestCallBack = () => {
  contactStore.isContactModal = true;
};
</script>
