<template>
  <div v-if="acitveTools.length" class="aside-fixed-control">
    <transition-group>
      <responsive-item v-for="item in currTools" :key="item.id" :item="item"></responsive-item>
    </transition-group>
  </div>
</template>

<script setup lang="ts">
import 'sass/cabinet/aside-fixed-control.sass';
import 'sass/plugin/perfect-scrollbar.css';
import 'sass/cabinet/scrollbar-custom-styles.scss';
import 'sass/detailed-article/_similar-tags.sass';

import {computed} from '#imports';
import {useCabinetGoodsStore} from '@/stores/cabinetGoods';
import {useCompareStore} from '@/stores/compare';
import {useToolsStore} from '@/stores/tools';
import type {IControlTools} from '@/types/pages/cabinet/tools';
import {controlListData} from 'services/tools';
import ResponsiveItem from './item.vue';

const compareStore = useCompareStore();
const cabinetGoodsStore = useCabinetGoodsStore();

const tools = useToolsStore();
await tools.getPublicWidgets();

const acitveTools = computed<string[]>(() => tools.activeTools);

const compareCount = computed<number>(() => compareStore.ids.length);
const favouriteCount = computed<number>(() => cabinetGoodsStore.productsInFavorite.length);

const currTools = computed<IControlTools[]>(() =>
  controlListData.filter((el) => {
    if (acitveTools.value.includes(el.value!)) {
      if (el.value === 'favorite_list') {
        el.count = favouriteCount.value;
      } else if (el.value === 'compare_list') {
        el.count = compareCount.value;
      }
      return true;
    }
    return false;
  }),
);
</script>
