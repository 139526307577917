<template>
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.70801 2.54199H2.70801V7.54199H7.70801V2.54199ZM7.70801 13.792H2.70801V18.792H7.70801V13.792ZM18.958 2.54199H13.958V7.54199H18.958V2.54199ZM18.958 13.792H13.958V18.792H18.958V13.792ZM0.833008 0.666992V9.41699H9.58301V0.666992H0.833008ZM0.833008 11.917V20.667H9.58301V11.917H0.833008ZM20.833 0.666992V9.41699H12.083V0.666992H20.833ZM12.083 11.917V20.667H20.833V11.917H12.083Z"
      fill="#4B6286"
    />
  </svg>
</template>
