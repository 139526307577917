<template>
  <div class="header__menu">
    <div class="container">
      <div class="menu__wrapper" @mouseleave="selectedSubMenu = {}">
        <layout-header-menu-nav
          v-model="selectedSubMenu"
          :nav-links="navLinks"
          @select-menu="selectMenu($event[0], $event[1])"
        ></layout-header-menu-nav>
        <layout-header-menu-personal :small-info="smallInfo"></layout-header-menu-personal>
      </div>
    </div>
    <layout-mobile-header
      :nav-links="navLinks"
      :width="width"
      @show-sub-menu="selectedSubMenu.list = $event"
      @search-push="emit('search-push')"
    ></layout-mobile-header>
  </div>
</template>

<script setup lang="ts">
import {type PropType, computed} from '#imports';
import type {IGetBasketZeroResponse} from '@/types/store/basket';
import type {IChild2Response, IMainResponse} from '@/types/store/store';
import LayoutMobileHeader from '../../mobile/header.vue';
import LayoutHeaderMenuNav from './nav.vue';
import LayoutHeaderMenuPersonal from './personal.vue';

interface Menu {
  id: number;
  list: IChild2Response[];
}

const props = defineProps({
  modelValue: {type: Object as PropType<Menu>, required: true},
  width: {type: Number, required: true},
  navLinks: {type: Array as PropType<IMainResponse[]>, required: true},
  smallInfo: {type: Object as PropType<IGetBasketZeroResponse>, required: true},
  compareCoun: {type: Number, required: false},
});

const emit = defineEmits<{
  (e: 'update:modelValue', params: Menu): void;
  (e: 'search-push'): void;
}>();

const selectedSubMenu = computed({
  get: () => props.modelValue,
  set: (val) => {
    emit('update:modelValue', val);
  },
});

const selectMenu = (idx: number, elem: IChild2Response[]): void => {
  if (idx === selectedSubMenu.value.id) {
    return;
  }
  selectedSubMenu.value = {id: idx, list: elem};
};
</script>
