<template>
  <footer class="footer">
    <div class="footer__menu_wrapper">
      <div class="container">
        <div class="footer__logo">
          <logo-svg id="footer"></logo-svg>
        </div>
        <div
          class="footer__btn"
          :class="{footer__btn_bordered: isFooterMenuShown}"
          type="button"
          role="button"
          tabindex="0"
          @click="toggleIsFooterMenuShown()"
          @keydown.enter="toggleIsFooterMenuShown()"
        >
          <component
            :is="isFooterMenuShown ? CloseSvg : BarSvg"
            class="footer__btn_svg"
          ></component>
          {{ isFooterMenuShown ? 'Скрыть' : 'Меню' }}
        </div>
        <transition
          name="expand"
          @enter="useEnter($event)"
          @after-enter="useAfterEnter($event)"
          @leave="useLeave($event)"
        >
          <div v-show="isFooterMenuShown" class="footer__menu_block">
            <ul class="footer__menu">
              <li v-for="elem in navLinks" :key="elem.name">
                <component
                  :is="elem.url ? NuxtLink : 'span'"
                  class="footer__nav-item"
                  :class="{disabled: !elem.url}"
                  :to="elem.url || '#'"
                >
                  {{ elem.name }}
                </component>
              </li>
            </ul>
          </div>
        </transition>
      </div>
    </div>
    <div class="footer__info">
      <div class="container">
        <div class="footer__info_wrapper">
          <div class="info_wrapper">
            <info-column
              v-for="(item, idx) in aboutList"
              :key="idx"
              :class="item.class"
              :item="item"
            ></info-column>
          </div>
          <contact-info></contact-info>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import 'sass/default-layout/footer.scss';
import 'sass/default-layout/info.scss';
import 'sass/index.scss';

import {useToggle} from '@vueuse/core';
import {NuxtLink} from '#components';
import {computed, watch} from '#imports';
import {useAfterEnter, useEnter, useLeave} from '@/composables/useAnimations';
import {useRootStore} from '@/stores/root';
import BarSvg from 'svg/footer/bar.vue';
import CloseSvg from 'svg/footer/close.vue';
import LogoSvg from 'svg/logo.vue';
import ContactInfo from './contactInfo.vue';
import InfoColumn from './infoColumn.vue';

const rootStore = useRootStore();

const navLinks = computed(() => rootStore.menuData?.main || []);
const aboutList = computed(() => rootStore.menuData?.footer || []);

const {0: isFooterMenuShown, 1: toggleIsFooterMenuShown} = useToggle(false);

watch(
  () => rootStore.width,
  (width) => {
    isFooterMenuShown.value = width > 768;
  },
);
</script>
