<template>
  <div class="scrolled-bar">
    <teleport to="body">
      <transition>
        <div v-if="selectedSubMenu?.list?.length" class="header-bg"></div>
      </transition>
    </teleport>
    <div class="container">
      <div class="scrolled-bar_wrapper" @mouseleave="selectedSubMenu = undefined">
        <nuxt-link to="/">
          <logo-svg id="scrolled-bar" class="scrolled-bar__logo"></logo-svg>
        </nuxt-link>
        <nav class="scrolled-bar__menu">
          <ul class="scrolled-bar__list">
            <li
              v-for="(elem, i) in navLinks"
              :key="i"
              class="scrolled-bar__element scrolled-bar__submenu"
              @mouseover="selectMenu(i, elem.childs || [])"
            >
              <component
                :is="elem.url != null ? NuxtLink : 'span'"
                class="scrolled-bar__link hovered"
                :class="{disabled: !elem.url}"
                :to="elem.url ?? undefined"
              >
                {{ elem.name }}
              </component>
            </li>
          </ul>
          <transition>
            <sub-menu-item
              v-if="selectedSubMenu?.list?.length"
              :childs="selectedSubMenu.list"
            ></sub-menu-item>
          </transition>
        </nav>
        <div class="scrolled-bar__cabinet">
          <div class="scrolled-bar__profile">
            <layout-hovered-block
              class="personal__auth_sub"
              :basket-count="smallInfo.count"
              :compare-count="compareCount"
              :is-auth="isAuth"
            ></layout-hovered-block>
            <nuxt-link :to="isAuth ? CABINET : LOGIN" class="scrolled-bar__profile_wrapper hovered">
              <i class="scrolled-bar__icon">
                <profile-svg id="profile_scrolled"></profile-svg>
              </i>
              <span class="scrolled-bar__text">
                {{ isAuth ? 'Кабинет' : 'Войти' }}
              </span>
            </nuxt-link>
          </div>
          <nuxt-link to="/basket" class="scrolled-bar__cart header__cart hovered">
            <div class="cart__logo">
              <cart-svg></cart-svg>
            </div>
            <div class="cart__info">
              <div class="cart__count is-medium-desktop">
                {{ smallInfo.count }}
                {{ nounBasedOnCount('товар', smallInfo.count) }}
              </div>
              <div class="cart__count medium-desktop">сумма</div>
              <div v-if="basketStore.resultPrice && isAuth" class="cart__price">
                <span class="is-medium-desktop"> на сумму </span>
                <ui-price :price="totalPrice"></ui-price>
              </div>
            </div>
          </nuxt-link>
          <layout-hovered-cart-drop v-if="smallInfo.count"></layout-hovered-cart-drop>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import 'sass/default-layout/scrolled-bar.scss';

import {NuxtLink} from '#components';
import {type PropType, computed, ref, useRuntimeConfig} from '#imports';
import UiPrice from '@/components/UI/price.vue';
import {CABINET, LOGIN} from '@/composables/useURL';
import {useAuthStore} from '@/stores/auth';
import {useBasketStore} from '@/stores/basket';
import type {IGetBasketZeroResponse} from '@/types/store/basket';
import type {IChild2Response, IMainResponse} from '@/types/store/store';
import {nounBasedOnCount} from '@/utils/string';
import CartSvg from 'svg/header/cart.vue';
import ProfileSvg from 'svg/header/profile.vue';
import LogoSvg from 'svg/logo.vue';
import LayoutHoveredBlock from '../hovered/block/index.vue';
import LayoutHoveredCartDrop from '../hovered/cartDrop/index.vue';
import SubMenuItem from './subMenu.vue';

const props = defineProps({
  smallInfo: {type: Object as PropType<IGetBasketZeroResponse>, required: true},
  navLinks: {type: Array as PropType<IMainResponse[]>, required: true},
  compareCount: {type: Number, required: false, default: 0},
  favouriteCount: {type: Number, required: false, default: 0},
});

const config = useRuntimeConfig();

const basketStore = useBasketStore();
const authStore = useAuthStore();
const isAuth = computed(() => authStore.isAuth);

const totalPrice = computed(() => {
  const total = props.smallInfo.sum;
  const servicesCost = props.smallInfo.products.reduce(
    (sum, product) => sum + (product.servicePrice || 0),
    0,
  );
  return config.public.hidePrices ? servicesCost : total;
});

const selectedSubMenu = ref<{
  id: number;
  list: IChild2Response[];
}>();

const selectMenu = (idx: number, elem: IChild2Response[]) => {
  if (idx === selectedSubMenu.value?.id) {
    return;
  }
  selectedSubMenu.value = {
    id: idx,
    list: elem,
  };
};
</script>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
