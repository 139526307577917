<template>
  <nav class="menu">
    <ul v-if="navLinks.length > 0" class="menu__nav">
      <li
        v-for="(elem, i) in navLinks"
        :key="i"
        ref="menuItems"
        class="menu__element menu__submenu"
        :class="{
          menu__element_hovered: selectedSubMenu.id === i && selectedSubMenu.list?.length,
        }"
        tabindex="0"
        role="menuitem"
        @focusin="selectMenu(i, elem.childs)"
        @mouseover="selectMenu(i, elem.childs)"
      >
        <component
          :is="elem.url != null ? NuxtLink : 'span'"
          class="menu__link hovered"
          :to="removeLastSlashFromLink(elem.url) ?? undefined"
          :class="{
            disabled: !elem.url,
            menu__link_selected: elem.url != null && isMenuItemSelected(elem.url),
          }"
          @click="clearMenu()"
        >
          {{ elem.name }}
        </component>
      </li>
    </ul>
    <transition>
      <header-sub-menu
        v-if="selectedSubMenu.list && selectedSubMenu.list.length"
        :childs="selectedSubMenu?.list"
        @close-menu="clearMenu()"
      ></header-sub-menu>
    </transition>
  </nav>
</template>

<script setup lang="ts">
import {useDebounceFn} from '@vueuse/core';
import {NuxtLink} from '#components';
import {type PropType, computed, ref, useRoute} from '#imports';
import HeaderSubMenu from '@/components/default-layout/header/subMenu.vue';
import {useIsWithinDomRect} from '@/composables/useIsWithinDomRect';
import type {IChild2Response, IMainResponse} from '@/types/store/store';
import {removeLastSlashFromLink} from '@/utils';

interface MenuItem {
  idx: number;
  elem: object;
}

interface Menu {
  id: number;
  list: IChild2Response[];
}

const props = defineProps({
  modelValue: {type: Object as PropType<Menu>, required: true},
  navLinks: {
    type: Array as PropType<IMainResponse[]>,
    required: false,
    default: () => [],
  },
});

const emit = defineEmits<{
  (e: 'update:modelValue', params: Menu): void;
  (e: 'select-menu', params: MenuItem[]): void;
}>();

const route = useRoute();

const selectedSubMenu = computed({
  get: () => props.modelValue,
  set: (val) => {
    emit('update:modelValue', val);
  },
});

const isMenuItemSelected = (url: string) => route.fullPath.includes(url);

const isMenuChanged = ref(false);

const bounce = () => {
  isMenuChanged.value = true;
  setTimeout(() => {
    isMenuChanged.value = false;
  }, 1000);
};

const menuItems = ref<HTMLLIElement[]>([]);

const {isMouseWithinRect} = useIsWithinDomRect();

const selectMenu = useDebounceFn(
  (idx, childs) => {
    const selectedElement = menuItems.value.at(idx);
    if (!selectedElement || !isMouseWithinRect(selectedElement.getBoundingClientRect())) {
      return;
    }

    if (!isMenuChanged.value) {
      emit('select-menu', [idx, childs]);
    }
    bounce();
  },
  40,
  {maxWait: 40},
);

const clearMenu = () => {
  if (!isMenuChanged.value) {
    selectedSubMenu.value = {};
  }
  bounce();
};
</script>
